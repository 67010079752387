<template>
  <b-card :title="$t('counterparties')">
    <b-row>
      <b-col sm="12">
        <div class="text-right mb-2">
          <b-button variant="success" size="sm" @click="openModal"><fa icon="plus" /></b-button>
        </div>
        <b-table
          responsive
          striped
          hover
          show-empty
          sort-icon-left
          :filter="filter"
          :items="items"
          :fields="fields"
          :current-page="currentPage"
          @sort-changed="handleSortChange"
          :per-page="0" @filtered="onFiltered">
          <template v-slot:cell(company)="row">
            <span v-if="row.item.company.length > 0">
              <span v-for="c in row.item.company" :key="c.id">{{ c.name }}</span>
            </span>
          </template>
          <template v-slot:cell(first_name)="row">
            {{ row.item.last_name }} {{ row.item.first_name }} {{ row.item.surname }}
          </template>
          <template v-slot:cell(roles)="row">
            <strong>{{ row.item.roles.length > 0 ? row.item.roles[0].title : null }}</strong>
          </template>
          <template v-slot:cell(contacts)="row">
            <ul v-if="row.item.contacts.length > 0" class="list-unstyled">
              <li v-for="c in row.item.contacts" :key="c.id">
                <strong>{{ c.type }}</strong>: {{ c.value }}
              </li>
            </ul>
          </template>
          <template v-slot:cell(actions)="row">
            <b-button-group>
              <router-link v-if="row.item.company.length > 0" :to="{ name: 'company.info', params: { id: row.item.company[0].id } }" class="btn btn-sm btn-primary">Дет.</router-link>
              <b-button size="sm" variant="warning" @click="editInfoCounterparty(row.item.id)">Ред.</b-button>
            </b-button-group>
          </template>
        </b-table>
        <!-- Pagination -->
        <b-pagination size="sm" :total-rows="totalItems" v-model="currentPage" :per-page="perPage"/>
      </b-col>
    </b-row>
    <!-- modal -->
    <b-modal id="counterparty-modal" size="lg" hide-footer :title="form.id ? 'Змінити' : 'Додати'">
      <b-row>
        <b-col sm="8">
          <div class="form-group">
            <label for="name">{{ $t('company_name') }}</label>
            <input type="text" v-model="form.company_name" :class="{ 'is-invalid': form.errors.has('company_name') }" class="form-control form-control-sm">
            <has-error :form="form" field="company_name" />
          </div>
        </b-col>
        <b-col sm="12">
          <b-row>
            <b-col sm="4">
              <div class="form-group">
                <label for="name">{{ $t('first_name') }}</label>
                <input type="text" v-model="counterparty.first_name" class="form-control form-control-sm">
              </div>
            </b-col>
            <b-col sm="4">
              <div class="form-group">
                <label for="name">{{ $t('last_name') }}</label>
                <input type="text" v-model="counterparty.last_name" class="form-control form-control-sm" >
              </div>
            </b-col>
            <b-col sm="4">
              <div class="form-group">
                <label for="">{{ $t('middle_name') }}</label>
                <div class="input-group mb-3">
                  <input type="text" class="form-control form-control-sm" v-model="counterparty.middle_name" required>
                  <div class="input-group-append">
                    <b-button size="sm" id="button-addon2" @click="saveCounterparty">
                      <fa v-if="counterparty.id" icon="check" />
                      <fa v-else icon="plus" />
                    </b-button>
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="6">
              <div class="form-group">
                <label for="role">{{ $t('type') }}</label>
                <select class="form-control form-control-sm" v-model="counterparty.role" name="role" required>
                  <option v-for="(item, index) in roles" :key="index" :value="item.name">{{ item.title }}</option>
                </select>
              </div>
            </b-col>
            <b-col sm="6">
              <div class="form-group">
                <label for="name">{{ $t('position_in_company') }}</label>
                <input type="text" v-model="counterparty.position_in_company" required class="form-control form-control-sm">
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <hr>
      <h5>{{ $t('contacts') }}</h5>
      <b-row v-if="form.counterparties.length > 0">
        <b-col v-for="(c, idx) in form.counterparties" :key="idx" sm="12">
          <b-button size="sm" variant="outline-secondary" pill @click="changeCounterparty(c.id)"><fa icon="pencil-alt" /></b-button>
          <br>
          <strong>{{ $t('last_name') }}:</strong> {{ c.middle_name }}<br/>
          <strong>{{ $t('first_name') }}:</strong> {{ c.first_name }}<br/>
          <strong>{{ $t('middle_name') }}:</strong> {{ c.last_name }}<br/>
          <strong>{{ $t('type') }}:</strong> {{ c.role ? getRole(c.role).title : null }}<br/>
          <strong v-if="typeof c.company !== 'undefined' && c.company.length > 0">{{ $t('position_in_company') }}:</strong> {{ c.company[0].position_in_company }}<br/>
          <div v-for="(item, index) in c.contacts" :key="index">
            <strong>{{ item.type }}:</strong> {{ item.value }}
            <b-btn-group>
              <b-button size="sm" variant="outline-secondary" @click="toFormContact(c.id, item.id)"><fa icon="pencil-alt" /></b-button>
              <b-button size="sm" variant="outline-danger"><fa icon="times" /></b-button>
            </b-btn-group>
          </div>
          <hr/>
          <!-- form add -->
          <b-row class="align-items-center">
            <b-col sm="6">
              <div class="form-group">
                <label for="name">{{ $t('contacts') }}</label>
                <input type="text" required class="form-control form-control-sm" v-model="contacts.value">
              </div>
            </b-col>
            <b-col sm="6">
              <div class="form-group">
                <label for="role">{{ $t('relation_with_client') }}</label>
                <div class="input-group mb-3">
                  <select class="form-control form-control-sm" v-model="contacts.type" name="role">
                    <option v-for="(item, index) in relationWithClient" :key="index" :value="item">{{ item }}</option>
                  </select>
                  <div class="input-group-append">
                    <b-button size="sm" id="button-addon2" @click="saveContact(c.id)">
                      <fa v-if="contacts.id" icon="check" />
                      <fa v-else icon="plus" />
                    </b-button>
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>
          <!-- /form add -->
        </b-col>
      </b-row>
      <b-button size="sm" variant="primary" @click="save">{{ $t('save') }}</b-button>
    </b-modal>
  </b-card>
</template>
<script>
import Form from 'vform'
import axios from 'axios'
import { v4 as uuidv4 } from 'uuid'
export default {
  data: () => ({
    urlParams: {},
    roles: null,
    items: [],
    filter: [],
    fields: [
      {
        key: 'company',
        label: 'Компанія',
        class: 'text-left'
      },
      {
        key: 'first_name',
        label: 'ПІБ'
      },
      {
        key: 'contacts',
        label: 'Контакт'
      },
      {
        key: 'roles',
        label: 'Тип'
      },
      {
        key: 'we_indebted',
        label: 'Ми винні',
        class: 'text-center'
      },
      {
        key: 'we_owe',
        label: 'Нам винен'
      },
      {
        key: 'actions',
        label: 'Дії'
      }
    ],
    currentPage: 1,
    perPage: 15,
    totalItems: 0,
    form: new Form({
      id: null,
      type: 'create',
      company_name: '',
      counterparties: []
    }),
    counterparty: {
      position_in_company: '',
      first_name: '',
      last_name: '',
      middle_name: '',
      role: 'service'
    },
    users: [],
    contacts: {
      type: 'Телефон',
      value: ''
    },
    relationWithClient: [
      'Телефон',
      'Telegram',
      'Viber',
      'WhatsUp',
      'Messenger',
      'Email',
      'Site'
    ]
  }),
  mounted () {
    this.getData()
    this.getRoles()
  },
  methods: {
    async getData () {
      const params = this.buildQuery() !== '' ? '&' + this.buildQuery() : ''
      const apiUrl = process.env.VUE_APP_ENDPOINT_V1
      await axios.get(apiUrl + 'counterparties?page=' + this.currentPage + params).then(resp => {
        if (resp.data) {
          const data = resp.data
          this.items = resp.data.data
          this.currentPage = data.meta.pagination.current_page
          this.perPage = data.meta.pagination.per_page
          this.totalItems = data.meta.pagination.total
        }
      })
    },
    async getRoles () {
      const apiUrl = process.env.VUE_APP_ENDPOINT_V1
      await axios.get(apiUrl + 'roles/titles').then(resp => {
        if (resp.data) {
          this.roles = resp.data
        }
      })
    },
    handleSortChange (context) {},
    onFiltered () {},
    buildQuery () {
      return Object.entries(this.urlParams).map(pair => pair.map(encodeURIComponent).join('=')).join('&')
    },
    openModal () {
      this.form.reset()
      this.$bvModal.show('counterparty-modal')
    },
    async save () {
      if (this.form.type === 'create') {
        const apiUrl = process.env.VUE_APP_ENDPOINT_V1
        await this.form.post(apiUrl + 'counterparties/create').then(resp => {
          if (resp.data.status) {
            this.getData()
            this.form.reset()
          }
        }).catch(error => {
          this.form.errors.set(error.response.data.error.errors)
        })
      } else {
        const apiUrl = process.env.VUE_APP_ENDPOINT_V1
        await this.form.post(apiUrl + 'counterparties/update').then(resp => {
          if (resp.data.status) {
            this.getData()
            this.form.reset()
          }
        }).catch(error => {
          this.form.errors.set(error.response.data.error.errors)
        })
      }
    },
    getRole (name) {
      return this.roles.find(r => r.name === name)
    },
    saveContact (counterpartyId) {
      if (this.contacts.id) {
        this.editContact(counterpartyId, this.contacts.id)
      } else {
        this.addContact(counterpartyId)
      }
    },
    addContact (uuid) {
      const user = this.form.counterparties.find(u => u.id === uuid)
      if (typeof user !== 'undefined') {
        if (user && user.contacts) {
          user.contacts.push({
            id: uuidv4(),
            type: this.contacts.type,
            value: this.contacts.value
          })
        } else {
          user.contacts = []
          user.contacts.push({
            id: uuidv4(),
            type: this.contacts.type,
            value: this.contacts.value
          })
        }
      }
      this.contacts = {
        type: 'Телефон',
        value: ''
      }
    },
    editContact (counterpartyId, cId) {
      const counterparty = this.form.counterparties.find(c => c.id === counterpartyId)
      if (typeof counterparty !== 'undefined') {
        const contact = counterparty.contacts.find(c => c.id === cId)
        if (typeof contact !== 'undefined') {
          contact.type = this.contacts.type
          contact.value = this.contacts.value
          this.contacts = {
            type: 'Телефон',
            value: ''
          }
        }
      }
    },
    toFormContact (counterpartyId, id) {
      const counterparty = this.form.counterparties.find(c => c.id === counterpartyId)
      if (typeof counterparty !== 'undefined') {
        const contact = counterparty.contacts.find(c => c.id === id)
        if (typeof contact !== 'undefined') {
          this.contacts = contact
        }
      }
    },
    saveCounterparty () {
      if (this.counterparty.id) {
        this.updateCounterparty(this.counterparty.id)
      } else {
        this.addCounterparty()
      }
      this.counterparty = {
        position_in_company: '',
        first_name: '',
        last_name: '',
        middle_name: '',
        role: 'service'
      }
    },
    addCounterparty () {
      if (this.counterparty.first_name !== '' && this.counterparty.last_name !== '') {
        this.form.counterparties.push({
          id: uuidv4(),
          first_name: this.counterparty.first_name,
          middle_name: this.counterparty.middle_name,
          last_name: this.counterparty.last_name,
          role: this.counterparty.role,
          position_in_company: this.counterparty.position_in_company,
          company: [
            { position_in_company: this.counterparty.position_in_company }
          ]
        })
      }
    },
    updateCounterparty (id) {
      let counterparty = this.form.counterparties.find(c => c.id === id)
      if (typeof counterparty !== 'undefined') {
        counterparty = this.counterparty
      }
    },
    changeCounterparty (id) {
      const counterparty = this.form.counterparties.find(c => c.id === id)
      if (typeof counterparty !== 'undefined') {
        this.counterparty = counterparty
      }
    },
    editInfoCounterparty (id) {
      this.form.reset()
      const counterparty = this.items.find(i => i.id === id)
      if (typeof counterparty !== 'undefined') {
        this.form.type = 'edit'
        this.form.company_name = counterparty.company[0].name
        this.form.counterparties.push({
          id: counterparty.id,
          first_name: counterparty.first_name,
          last_name: counterparty.last_name,
          middle_name: counterparty.surname,
          position_in_company: counterparty.company[0].position_in_company,
          role: counterparty.roles.length > 0 ? counterparty.roles[0].name : null,
          contacts: counterparty.contacts,
          company: counterparty.company
        })
        this.$bvModal.show('counterparty-modal')
      }
    }
  },
  watch: {
    currentPage: {
      handler: function (value) {
        this.getData()
      }
    }
  }
}
</script>
